import React, { forwardRef, useEffect, useRef, useState } from 'react';
import Modal from 'shared/components/Modal/Modal';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import { useTranslation } from 'react-i18next';
import { createPortal } from 'react-dom';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import { ModalTypeEnum } from 'modules/Room/RoomModals/constants/modalEnums';
import { useAccount } from 'wagmi';
import { useRoomStore } from 'shared/store/roomStore';
import { RoomStatusEnum } from 'shared/constants/shared-enums';

const WatchGameModal = forwardRef((props, ref) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { closeAllModals, setModalState } = useModalStore();
  const { address } = useAccount();
  const { resetRoom, roomStatus } = useRoomStore();
  const goToSetBet = () => {
    closeAllModals();
    navigate(ROUTES.ROOM);
    resetRoom(address!.toLowerCase() as `0x${string}`);
    // setModalState(ModalTypeEnum.SET_BET, true);
    navigate(ROUTES.LOBBY);
  };
  const hostElement = document.querySelector('.watch-game-card');
  if (!hostElement) return null;
  return createPortal(
    <Modal classNames="left-[3%] w-[94%]">
      <div className="flex flex-col items-center">
        <TextWithLeftColorLine colorClass={'bg-green'} className={'mb-[20px]'}>
          {roomStatus === RoomStatusEnum.Closed ? (
            <>
              <p className="text-white text-[12px] sm:text-[17px] text-justify mb-[10px]">
                {t('modals.watchGame.viewing')}
              </p>
              <p className="text-white text-[12px] sm:text-[17px] text-justify">
                {t('modals.watchGame.start')}
              </p>
            </>
          ) : (
            <p className="text-white text-[12px] sm:text-[17px] text-justify">
              {t('modals.watchGame.watch')}
            </p>
          )}
        </TextWithLeftColorLine>
        <PrimaryButton
          text={'modals.watchGame.goToSetBet'}
          onClick={goToSetBet}
        />
      </div>
    </Modal>,
    hostElement as Element
  );
});

export default WatchGameModal;
