import logo from 'assets/png/logo.png';
import ControllerIcon from 'assets/controller.svg?react';
import { useTranslation } from 'react-i18next';
import ArrowLeftIcon from 'assets/arrow-left.svg?react';
import { BurgerMenu } from 'shared/components/burger-menu';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from '@/shared/constants';
import { RoomStatusEnum } from '@/shared/constants/shared-enums';

interface HeaderProps {
  roomStatus: RoomStatusEnum;
}

export const Header = ({ roomStatus }: HeaderProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleRedirectToHome = () => {
    navigate(ROUTES.HOME);
  }

  const handleRedirectToLobby = () => {
    navigate(ROUTES.LOBBY);
  }

  return (
    <div className='flex justify-between w-full px-[16px] lg:px-[64px] py-[16px] items-center'>
      <div className='flex items-center gap-[24px]'>
        <div onClick={handleRedirectToHome}>
          <img
            className="w-[61px] lg:w-[94px] cursor-pointer"
            src={logo}
            alt={'rock paper scissors logo'}
          />
        </div>

        <div className='w-[3px] bg-[#272D63] h-[56px] hidden lg:flex' />

        <div className='text-[#C0C0FF] font-[600] text-[20px] flex items-center gap-[8px] cursor-pointer hidden lg:flex' onClick={handleRedirectToLobby}>
          <ArrowLeftIcon />
          Back to streams
        </div>
      </div>
      <div className='gradient-title text-[18px] lg:text-[40px] font-bold flex items-center gap-[8px]'>
        {roomStatus === RoomStatusEnum.Closed ? 'Game Replay' : 'Live Game'}
        {roomStatus !== RoomStatusEnum.Closed ? <div className='w-[16px] h-[16px] rounded-[50%] bg-[#0AF33D] animate-pulse' /> : null}
      </div>

      <div className='flex items-center gap-[24px]'>
        <div
          className={`justify-center gap-2 rounded-[16px] border border-[#FFE081] flex m-[1px] h-[54px] sm:px-[35px] items-center bg-create-btn text-white font-semibold text-[16px] sm:text-[22px] hidden lg:flex cursor-pointer`}
          onClick={handleRedirectToLobby}
        >
          <ControllerIcon className="w-5 h-5" />
          {t('pages.liveGamesSection.create')}
        </div>
        <div className='w-[3px] bg-[#272D63] h-[56px] hidden lg:flex' />
        <BurgerMenu className="h-[46px] w-[46px]" />
      </div>
    </div>
  )
}