import { FC, useMemo } from 'react';
import { RoundWinnerOutcomes } from 'shared/constants/shared-enums';
import { renderStreamIcon } from '@/shared/components/StreamHistoryIcon/renderStreamIconByMove';

interface Props {
  round: {
    moveA: number;
    moveB: number;
    winner: RoundWinnerOutcomes;
  };
  gameCount: number;
  show?: boolean;
}

const HistoryMove: FC<Props> = ({ round, gameCount, show }) => {
  const playerAMoveIcon = useMemo(
    () => renderStreamIcon(round.moveA, round.winner === RoundWinnerOutcomes.PlayerA, false, round.winner === RoundWinnerOutcomes.Draw),
    [round]
  );

  const playerBMoveIcon = useMemo(
    () => renderStreamIcon(round.moveB, round.winner === RoundWinnerOutcomes.PlayerB, false, round.winner === RoundWinnerOutcomes.Draw),
    [round]
  );
  if (!show) {
    return null;
  }
  return (
    <div className="flex items-center flex-col m-[4px] lg:m-[8px]">
      <div
        className={`flex justify-between items-center w-full bg-[#0D1450] rounded-[12px] p-[6px] lg:p-[12px]`}
      >
        <div className={`flex w-[33%] justify-start order-1`}>
          {playerAMoveIcon}
        </div>
        <span className="order-2 w-[34%] flex justify-center text-[16px] lg:text-[30px] font-medium text-white">
          {gameCount}
        </span>
        <div className={`flex w-[33%] justify-end order-3`}>
          {playerBMoveIcon}
        </div>
      </div>
    </div>
  );
};

export default HistoryMove;
