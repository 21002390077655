import React, { useEffect } from 'react';
import ModalWithFullScreenBg from 'shared/components/Modal/ModalWithFullScreenBg';
import {
  ModalPositionEnum,
  ModalTypeEnum,
} from 'modules/Room/RoomModals/constants/modalEnums';
import TextWithLeftColorLine from 'shared/components/TextWithLeftColorLine/TextWithLeftColorLine';
import { useTranslation } from 'react-i18next';
import Kraken from 'assets/png/kraken.png';
import Binance from 'assets/png/binance.png';
import CoinBaseLogo from 'assets/CoinbaseLogo.svg?react';
import { useModalStore } from 'modules/Room/RoomModals/store/modalStore';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import PrimaryButton from 'shared/components/PrimaryButton/PrimaryButton';
import { useNavigate } from 'react-router-dom';
import { ROUTES } from 'shared/constants';

interface Props {
  open: boolean;
}

const NotEnoughBalanceModal = ({ open }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const linkHandler = () => {
    setModalState(ModalTypeEnum.NOT_ENOUGH_COINS, false);
    // setModalState(ModalTypeEnum.SET_BET, true);
    navigate(ROUTES.LOBBY);
  };

  const isMobile = useMediaQuery('(max-width: 1200px)');
  const {
    closeAllModals,
    setModalState,
    setNotEnoughModalContinue,
    notEnoughModalState,
  } = useModalStore();
  useEffect(() => {
    return () => {
      setNotEnoughModalContinue(false);
    };
  }, [setNotEnoughModalContinue]);
  const continueHandler = () => {
    closeAllModals();
    // setModalState(ModalTypeEnum.SET_BET, true);
    navigate(ROUTES.LOBBY);
  };

  return (
    <ModalWithFullScreenBg
      key={isMobile ? 'ModalPositionEnum.CENTER' : 'ModalPositionEnum.LEFT'}
      open={open}
      position={isMobile ? ModalPositionEnum.CENTER : ModalPositionEnum.LEFT}
      className="light-shade"
    >
      <div className="sm:w-full sm:min-w-[592px] p-[20px] sm:p-[30px]">
        <TextWithLeftColorLine colorClass="bg-red" className="mb-[20px]">
          {notEnoughModalState.zeroBalance && (
            <>
              <p className="text-white text-[12px] sm:text-[17px] mb-[10px]">
                {t('modals.topUpModal.empty')}
              </p>
              <p className="text-white text-[12px] sm:text-[17px] mb-[10px]">
                {t('modals.topUpModal.oops')}
              </p>
              <p className="text-white text-[12px] sm:text-[17px]">
                {t('modals.topUpModal.links')}
              </p>
            </>
          )}
          {!notEnoughModalState.zeroBalance && (
            <>
              <p className="text-white text-[12px] sm:text-[17px] mb-[10px]">
                {t('modals.topUpModal.insufficient')}
              </p>
              <p className="text-white text-[12px] sm:text-[17px]">
                {t('modals.topUpModal.topUp')}
              </p>
            </>
          )}
        </TextWithLeftColorLine>
        <div className="flex items-center justify-between pb-[40px] border-border border-t-2 border-b-2 mb-[29px] pt-[40px]">
          <a
            onClick={linkHandler}
            href="https://www.kraken.com/"
            target="_blank"
            className="flex justify-center w-[33%]"
          >
            <img src={Kraken} alt="Kraken" className="h-[15px] sm:h-[23px]" />
          </a>
          <a
            href="https://www.binance.com/en"
            onClick={linkHandler}
            target="_blank"
            className="flex justify-center w-[33%]"
          >
            <img src={Binance} alt="Binance" className="h-[20px] sm:h-[30px]" />
          </a>
          <a
            onClick={linkHandler}
            href="https://www.coinbase.com/"
            target="_blank"
            className="flex justify-center w-[33%]"
          >
            <CoinBaseLogo className="h-[15px] sm:h-[25px]" />
          </a>
        </div>
        <div className="flex items-center flex-col">
          {notEnoughModalState.zeroBalance && (
            <p className="text-white text-[12px] sm:text-[17px]">
              {t('modals.topUpModal.tip')}
            </p>
          )}

          {notEnoughModalState.continue && (
            <PrimaryButton
              className={`self-center mt-[20px] ${
                !notEnoughModalState.zeroBalance ? 'mt-[20px]' : ''
              }`}
              text={'modals.topUpModal.continue'}
              onClick={continueHandler}
            />
          )}
        </div>
      </div>
    </ModalWithFullScreenBg>
  );
};

export default NotEnoughBalanceModal;
