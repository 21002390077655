import React from 'react';
import BurgerIcon from 'assets/burger.svg?react';
import { Overlay } from './overlay';

interface Props {
  className?: string;
}

export const BurgerMenu = ({ className }: Props) => {
  const [open, setOpen] = React.useState(false);
  const clickHandler = () => {
    setOpen(!open);
  };
  return (
    <>
      <div onClick={clickHandler} className='cursor-pointer'>
        <BurgerIcon className={className ?? ''} />
      </div>
      <Overlay open={open} setOpen={setOpen} />
    </>
  );
};

