import React, { useState } from 'react';
import  SearchIcon from 'assets/search.svg?react';
import CloseXIcon from 'assets/closeXOneMore.svg?react';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@/shared/hooks/useMediaQuery';

interface Props {
  setSearch: (value: string) => void;
}

export const SearchBar = ({ setSearch }: Props) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState('');
  const [isFocused, setIsFocused] = useState(false);
  const isTablet = useMediaQuery('(min-width: 768px)');

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setInputValue(value);
    setSearch(value);
  };

  const clearInput = () => {
    setInputValue('');
    setSearch('');
  };

  return (
    <div className="relative w-full lg:w-[300px]">
      <div className="relative flex items-center bg-indigo rounded-[8px] lg:rounded-[24px]">
        <input
          className="block w-full max-w-[270px] px-4 py-2 rounded-[24px] bg-transparent text-violet-text outline-none placeholder-white/40 text-[10px] sm:text-lg overflow-hidden text-ellipsis"
          placeholder={t('pages.liveGamesSection.search')}
          value={inputValue}
          onChange={handleInputChange}
          onFocus={() => setIsFocused(true)}
          onBlur={() => setIsFocused(false)}
          style={{
            direction: !isFocused && inputValue ? 'rtl' : 'ltr',
            textAlign: 'left',
          }}
        />
        {inputValue && (
          <button
            type="button"
            className="absolute right-4 flex items-center"
            onClick={clearInput}
          >
            <CloseXIcon />
          </button>
        )}
        {!inputValue && (
          <SearchIcon
            className="absolute right-4 pointer-events-none text-white w-4"
          />
        )}
      </div>
    </div>
  );
};
