import floor from 'assets/png/floor.png';
import humanStreamLeft1 from 'assets/png/human-stream-left-1.png';
import humanStreamLeft2 from 'assets/png/human-stream-left-2.png';
import humanStreamRight1 from 'assets/png/human-stream-right-1.png';
import humanStreamRight2 from 'assets/png/human-stream-right-2.png';

import { useEffect, useState } from "react";
import { Header, HistoryMoves, MoveCards, PlayerCard, RoomBox, Score } from "./components";
import { MovesEnum } from '@/shared/constants/shared-enums';
import ControllerIcon from 'assets/controller.svg?react';
import { TimeAgo } from '@/shared/components/time-ago/time-ago';
import { useTranslation } from 'react-i18next';
import { HistoryModal } from './components/history-moves/history-modal';
import { Phase, useStreamStore } from '@/shared/store/streamStore';
import { useNewRoomStore } from '@/shared/store/room-store';
import { usePublicClient } from 'wagmi';
import { useParams } from 'react-router-dom';
import AnimatedTransition from '@/shared/components/AnimatedTransition/AnimatedTransition';
import { useMediaQuery } from 'usehooks-ts';

const leftGuys = [humanStreamLeft1, humanStreamLeft2];
const rightGuys = [humanStreamRight1, humanStreamRight2];

export const StreamRoom = () => {
  const [isGuysAnimating, setIsGuysAnimating] = useState(false);
  const [playerACardsAnimating, setPlayerACardsAnimating] = useState(false);
  const [playerBCardsAnimating, setPlayerBCardsAnimating] = useState(false);
  const [playerAWonRound, setPlayerAWonRound] = useState(false);
  const [playerBWonRound, setPlayerBWonRound] = useState(false);
  const [roundDraw, setRoundDraw] = useState(false);
  const [playerASelectedMove, setPlayerASelectedMove] = useState(MovesEnum.None);
  const [playerBSelectedMove, setPlayerBSelectedMove] = useState(MovesEnum.None);
  const [playerAWins, setPlayerAWins] = useState(0);
  const [playerBWins, setPlayerBWins] = useState(0);

  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState(false);
  const [guysIndex, setGuysIndex] = useState(0);
  const isMobile = useMediaQuery('(max-width: 768px)');

  const { t } = useTranslation();

  const { startStopRoom, room, rounds } = useNewRoomStore();
  const { manageQueue, currentPhase, currentRound, queue } = useStreamStore();
  const publicClient = usePublicClient();
  const { activeRoomId } = useParams();

  useEffect(() => {
    let phase: Phase = 'break';
    if (
      !rounds?.[room?.roundCounter - 1]?.playerA?.encrMove &&
      !rounds?.[room?.roundCounter - 1]?.playerB?.encrMove
    ) {
      phase = 'playersThinking';
    } else if (
      (rounds?.[room?.roundCounter - 1]?.playerA?.encrMove &&
        !rounds?.[room?.roundCounter - 1]?.playerB?.encrMove) ||
      (rounds?.[room?.roundCounter - 1]?.playerB?.encrMove &&
        !rounds?.[room?.roundCounter - 1]?.playerA?.encrMove)
    ) {
      phase = 'onePlayerChooseMove';
    } else if (
      rounds?.[room?.roundCounter - 1]?.playerA?.encrMove &&
      rounds?.[room?.roundCounter - 1]?.playerB?.encrMove
    ) {
      phase = 'done';
    }
    manageQueue({ round: room.roundCounter, phase });
  }, [room, rounds]);

  useEffect(() => {
    if (activeRoomId && publicClient) {
      startStopRoom({ provider: publicClient, roomId: +activeRoomId });
    } else {
      startStopRoom({ provider: null, roomId: null });
    }
  }, [activeRoomId]);

  const handleOpenHistoryModal = () => {
    setIsHistoryModalOpen(true);
  }

  const handleCloseHistoryModal = () => {
    setIsHistoryModalOpen(false);
  }

  useEffect(() => {
    let interval: ReturnType<typeof setInterval>;
    let timeout: ReturnType<typeof setTimeout>;

    if (!currentPhase) {
      return;
    }

    if (currentPhase === 'playersThinking') {
      const startAnimation = () => {
        setIsGuysAnimating(true);
        interval = setInterval(() => {
          setGuysIndex((prevIndex) => (prevIndex + 1) % leftGuys.length);
        }, 200);
        timeout = setTimeout(() => {
          clearInterval(interval);
          setIsGuysAnimating(false);
        }, 1200);
      };

      if (isGuysAnimating) {
        startAnimation();
      } else {
        timeout = setTimeout(() => {
          startAnimation();
        }, 1500);
      }

      return () => {
        clearInterval(interval);
        clearTimeout(timeout);
      };
    }

    if (currentPhase === 'onePlayerChooseMove' || currentPhase === 'twoPlayersChooseMove') {
      setPlayerACardsAnimating(true);
      setPlayerBCardsAnimating(true);
    }

    if (currentPhase === 'playersMadeReveal') {
      const round = rounds?.[currentRound - 1];
      setPlayerACardsAnimating(false);
      setPlayerBCardsAnimating(false);
      //TODO use enum for winner outcome
      setPlayerAWonRound(round?.winner === 1 ? true : false);
      setPlayerBWonRound(round?.winner === 2 ? true : false);
      setRoundDraw(round?.winner === 3 ? true : false);
      setPlayerASelectedMove(round?.playerA?.move || MovesEnum.None);
      setPlayerBSelectedMove(round?.playerB?.move || MovesEnum.None);
      let playerAWinsActual = 0;
      let playerBWinsActual = 0;
      const roundsToCount = rounds?.slice(0, currentRound);
      roundsToCount?.forEach((round) => {
        if (round.winner === 1) {
          playerAWinsActual++;
        }
        if (round.winner === 2) {
          playerBWinsActual++;
        }
      });
      setPlayerAWins(playerAWinsActual);
      setPlayerBWins(playerBWinsActual);
    }

    if (currentPhase === 'done' && (queue.length !== 0 && queue.length !== 1)) {
      setPlayerAWonRound(false);
      setPlayerBWonRound(false);
      setPlayerASelectedMove(MovesEnum.None);
      setPlayerBSelectedMove(MovesEnum.None);
    }

    if (!queue.length) {
      setPlayerACardsAnimating(false);
      setPlayerBCardsAnimating(false);
    }
  }, [isGuysAnimating, currentPhase, currentRound, rounds, queue]);

  const renderEndRoundPhrase = () => {
    if (queue.filter((item) => item.phase === 'done').length === 1 || queue.length === 0) {
      return (
        <div className='absolute top-[300px] transform -translate-x-1/2 left-1/2 min-w-[420px] text-[60px] leading-[100px] font-[900] gradient-title-gold text-center text-uppercase'>
          {playerAWonRound ? 'Player A won' : 'Player B won'}
        </div>
      )
    }

    if (rounds?.[currentRound - 1].winner === 1) {
      return (
        <div className='absolute top-[280px] transform -translate-x-1/2 left-1/2 min-w-[420px] text-center flex flex-col items-center'>
          <div className='text-[60px] leading-[80px] font-[900] gradient-title-gold text-uppercase'>
            Player A
          </div>
          <div className='text-[40px] leading-[64px] text-[#fff] font-[900]'>
            won this round
          </div>
        </div>
      )
    }

    if (rounds?.[currentRound - 1].winner === 2) {
      return (
        <div className='absolute top-[280px] transform -translate-x-1/2 left-1/2 min-w-[420px] text-center flex flex-col items-center'>
          <div className='text-[60px] leading-[80px] font-[900] gradient-title-gold text-uppercase'>
            Player B
          </div>
          <div className='text-[40px] leading-[64px] text-[#fff] font-[900]'>
            won this round
          </div>
        </div>
      )
    }

    if (rounds?.[currentRound - 1].winner === 3) {
      return (
        <div className='absolute top-[300px] transform -translate-x-1/2 left-1/2 min-w-[420px] text-center flex flex-col items-center'>
          <div className='text-[60px] leading-[100px] font-[900] gradient-title text-uppercase'>
            Draw
          </div>
        </div>
      )
    }
  }

  return (
    <>
      <div className="w-full h-full overflow-hidden relative">
        <div className="flex flex-col items-center h-screen overflow-hidden">
          <Header roomStatus={room.roomStatus} />
          <div className="relative flex flex-row items-stretch justify-between max-w-[1920px] max-h-[1080px] lg:min-h-[800px] lg:w-full h-full">
            <div className="animate-appear-with-blur relative flex flex-row items-stretch lg:justify-between gap-[12px] items-center max-w-[1920px] p-[8px] lg:p-[0] max-h-[1080px] lg:min-h-[800px] w-full lg:h-full
              lg:py-4 rounded-[24px] mx-[16px] h-fit
              border lg:border-[0] border-border transition bg-opacity-30 bg-background lg:bg-[transparent]
            ">
              <div
                className='order-1 sm:max-w-[630px] sm:max-h-[890px] lg:w-[32%] lg:w-full z-30 animate-appear-with-blur flex justify-center lg:mt-[32px] h-fit'
              >
                <PlayerCard
                  position='order-1'
                  name="Player A"
                  wins={playerBWins}
                  playerAddress={room.playerA.address}
                />
              </div>
              <div
                className='z-30 flex flex-col order-2 animate-appear h-fit relative'
              >
                <Score playerAWins={playerAWins} playerBWins={playerBWins} />
                <HistoryMoves currentRound={currentRound} onOpenHistoryModal={handleOpenHistoryModal} />

                <AnimatedTransition show={(currentPhase === 'playersMadeReveal' || currentPhase === 'playerAWonRound' || (currentPhase === 'done' && (!queue.length || queue.length === 1 || queue.length === 2))) && !isMobile}>
                  <>
                    {renderEndRoundPhrase()}
                  </>
                </AnimatedTransition>
              </div>
              <div
                className='order-3 sm:max-w-[630px] lg:w-[32%] sm:max-h-[890px] lg:w-full z-30 animate-appear-with-blur  flex justify-center lg:mt-[32px] h-fit'
              >
                <PlayerCard
                  position='order-3'
                  name="Player B"
                  wins={playerAWins}
                  playerAddress={room.playerB.address}
                />
              </div>
            </div>
            <img
              src={leftGuys[guysIndex]}
              alt="guy"
              className='absolute top-[160px] lg:top-[310px] left-[-86px] lg:left-[46px] z-[100] animate-slide-in-from-left-without-blur w-[182px] h-[298px] lg:w-[375px] lg:h-[572px]'
            />
            <div className='absolute top-[134px] lg:top-[40%] left-[80px] lg:left-[420px] z-[100] animate-slide-in-from-left-without-blur w-[50px] h-[50px]'>
              <MoveCards
                position='left'
                isAnimating={playerACardsAnimating}
                isRoundFinished={playerAWonRound || playerBWonRound}
                selectedMove={playerASelectedMove}
                isWinner={playerAWonRound}
                roundDraw={roundDraw}
              />
            </div>
            <img
              src={rightGuys[guysIndex]}
              alt="guy"
              className='absolute top-[160px] lg:top-[310px] right-[-86px] lg:right-[46px] z-[100] animate-slide-in-from-right-without-blur w-[182px] h-[298px] lg:w-[375px] lg:h-[572px]'
            />
            <div className='absolute top-[134px] lg:top-[40%] right-[80px] lg:right-[470px] z-[100] animate-slide-in-from-right-without-blur w-[50px] h-[50px]'>
              <MoveCards
                position='right'
                isAnimating={playerBCardsAnimating}
                isRoundFinished={playerAWonRound || playerBWonRound}
                selectedMove={playerBSelectedMove}
                isWinner={playerBWonRound}
                roundDraw={roundDraw}
              />
            </div>

            <RoomBox token={room.token} bet={room.bet*2} animationsTriggered={true} />
          </div>
        </div>

        <div className="bg-grape flex justify-center items-center flex-col p-4 fixed bottom-0 w-full border-t-[1px] border-[#272D63] lg:hidden">
          <button
            className={`rounded-[12px] w-full mb-3`}
            onClick={() => {}}
          >
            <div
              className={`justify-center gap-2 rounded-[8px] border border-[#FFE081] flex m-[1px] p-2 sm:px-[35px] bg-create-btn text-white font-semibold text-[16px] sm:text-[22px]`}
            >
              <ControllerIcon className="w-5 h-5" />
              {t('pages.stream.playGame')}
            </div>
          </button>
          <div className="text-[8px] text-block-text flex gap-2">
            {/* {!!blockNumber && ( */}
            <span>
                Polygon block: 1234567
              {/* {chainId && chains[chainId as keyof typeof chains]} block: {+blockNumber.toString()} */}
              </span>
            {/* )} */}
            <span>|</span>
            <TimeAgo time={100} />
          </div>
        </div>
      </div>
      <HistoryModal isOpen={isHistoryModalOpen} onClose={handleCloseHistoryModal} currentRound={currentRound}/>
      <img
        src={floor}
        alt="floor"
        className="absolute top-[370px] lg:top-[inherit] lg:bottom-0 left-0 right-0 z-0 h-[20%] w-full"
      />
    </>
  );
}
