import RoomMenu from "modules/Room/features/RoomMenu/RoomMenu"
import { CreateGameSection, LiveGamesSection } from "../../components"

export const DesktopView = () => {
  return (
    <div className="relative w-full h-full min-h-[100vh] pt-[100px] flex max-w-[1800px] mx-auto justify-end lg:justify-between p-[24px] gap-[32px] flex-col-reverse lg:flex-row">
      <RoomMenu />
      <LiveGamesSection />
      <CreateGameSection />
    </div>
  )
}
