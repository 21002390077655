import React, { ButtonHTMLAttributes } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}
// TODO change border to gradient
export const OrangeButton = ({ children, className, ...props }: Props) => {
  return (
    <button
      className={`w-full bg-orange-gradient py-1.5 rounded-[12px] border border-orange-btn ${className ?? ''}`}
      {...props}
    >
      {children}
    </button>
  );
}
