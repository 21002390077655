import { useReadRspContractGetAllRoomRounds } from 'shared/features/contract.abi';
import { useParams } from 'react-router-dom';
import { useEffect, useRef } from 'react';
import { useRoomStore } from 'shared/store/roomStore';
import {
  RoomStatusEnum,
  RoundOutcome,
  RoundWinnerOutcomes,
} from 'shared/constants/shared-enums';

export const useGetHistoryMoves = (currentRound) => {
  const { roundCounter, roomStatus, amIPlayerA, setLastRoundOutcome } =
    useRoomStore();
  const { activeRoomId } = useParams();
  const { data, refetch } = useReadRspContractGetAllRoomRounds({
    args: [BigInt(activeRoomId ? activeRoomId : 1)],
  });
  const initialRender = useRef(true);

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    } else {
      refetch();
    }
  }, [roundCounter, currentRound]);

  useEffect(() => {
    if (roomStatus === RoomStatusEnum.Closed) {
      refetch();
    }
  }, [roomStatus]);
  useEffect(() => {
    if (data?.[data.length - 2]?.winner) {
      let outcome = RoundOutcome.Lose;
      if (
        amIPlayerA &&
        data[data.length - 2].winner === RoundWinnerOutcomes.PlayerA
      ) {
        outcome = RoundOutcome.Win;
      }
      if (
        !amIPlayerA &&
        data[data.length - 2].winner === RoundWinnerOutcomes.PlayerB
      ) {
        outcome = RoundOutcome.Win;
      }
      if (data[data.length - 2].winner === RoundWinnerOutcomes.Draw) {
        outcome = RoundOutcome.Draw;
      }
      setLastRoundOutcome(outcome);
    }
  }, [data]);
  return data ? [...data].reverse() : [];
};
