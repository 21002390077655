import React, { useEffect } from 'react';
import { useAccount, usePublicClient } from 'wagmi';
import MobileRoom from 'modules/Room/pages/MobileRoom';
import DesktopRoom from 'modules/Room/pages/DesktopRoom';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { useNavigate, useParams } from 'react-router-dom';
import { CHAINS_IDS } from 'shared/config';
import { ROUTES } from 'shared/constants';
import { useActiveGameRedirect } from 'modules/Room/Hooks/useActiveGameRedirect';
import { useActiveRoom } from 'modules/Room/Hooks/useActiveRoom';
import { RoomModals } from 'modules/Room/RoomModals';
import { usePlayerGamesStore } from 'shared/store/playerGamesStore';
import { useTransactionReceipt } from 'shared/hooks/useTransactionReceipt';
import { useSaltGenerator } from 'modules/Room/Hooks/useSaltGenerator';
import { SignModal } from 'modules/ConnectPage/components/SignModal/SignModal';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useShowSignModal } from 'shared/hooks/useShowSignModal';
import { useNewRoomStore } from 'shared/store/room-store';
import { useStreamStore } from 'shared/store/streamStore';

export const Room = () => {
  const { isConnected, chainId, address } = useAccount();
  const { showSignModal } = useShowSignModal();
  const isMobile = useMediaQuery('(max-width: 1200px)');
  const navigate = useNavigate();

  useEffect(() => {
    if (!isConnected) {
      navigate(ROUTES.HOME);
    }
  }, [isConnected]);
  useEffect(() => {
    if (chainId && !CHAINS_IDS.includes(chainId as 1 | 42161 | 137 | 8453)) {
      navigate(ROUTES.HOME);
    }
  }, [chainId, navigate]);
  useActiveGameRedirect();
  useActiveRoom();
  useSaltGenerator();
  const { setCloseHash, closeHash } = usePlayerGamesStore();
  const { isSuccess: isSuccessCloseUnusedRoom } = useTransactionReceipt(
    closeHash?.[chainId!]?.hash,
    closeHash?.[chainId!]?.date
  );
  useEffect(() => {
    if (isSuccessCloseUnusedRoom) {
      setCloseHash(chainId!, null);
    }
  }, [isSuccessCloseUnusedRoom]);

  if (!isConnected) return null;
  return (
    <>
      <AnimatedTransition show={showSignModal}>
        <SignModal />
      </AnimatedTransition>
      <RoomModals />
      {isMobile ? <MobileRoom /> : <DesktopRoom />}
    </>
  );
};
