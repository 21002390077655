import RoomMenu from 'modules/Room/features/RoomMenu/RoomMenu';
import {
  CreateGameSection,
  CreateGameSectionMobile,
  LiveGamesSection,
} from '../../components';
import React, { useState } from 'react';

import logo from 'assets/png/logo.png';
import MobileDropDown from 'modules/Room/mobile/MobileDropDown/MobileDropDown';
import { BurgerMenu } from 'shared/components/burger-menu';
import { useTranslation } from 'react-i18next';
import { LiveGamesSectionMobile } from 'modules/lobby-page/components/live-games-section';
import { useUIStore } from 'shared/store/uiStore';

export const MobileView = () => {
  const {lobby: {createGameOpen}, setLobbyCreateGameOpen} = useUIStore();
  
  const toggleCreateGameSection = () =>
    setLobbyCreateGameOpen(!createGameOpen);
  const { t } = useTranslation();
  return (
    <div className="relative w-full min-h-[100vh] flex flex-col">
      <div className="flex items-center justify-between px-[16px] py-[12px]">
        <img className="h-[50px]" src={logo} alt={'rock paper scissors logo'} />
        <div className="font-bold text-[20px] gradient-title">
          {createGameOpen
            ? t('modals.setBet.title')
            : t('pages.liveGamesSection.title')}
        </div>
        <BurgerMenu />
      </div>
      {createGameOpen && (
        <CreateGameSectionMobile openLiveGames={toggleCreateGameSection} />
      )}
      {!createGameOpen && (
        <LiveGamesSectionMobile openCreateGame={toggleCreateGameSection} />
      )}
    </div>
  );
};
