import create from 'zustand';

export type Phase =
  'break'
  | 'playersThinking'
  | 'onePlayerChooseMove'
  | 'twoPlayersChooseMove'
  | 'playersMadeReveal'
  | 'playerAWonRound'
  | 'done';

export const PhaseArray: Phase[] = [
  'playersThinking',
  'break',
  'onePlayerChooseMove',
  'twoPlayersChooseMove',
  'playersMadeReveal',
  'playerAWonRound',
  'done',
];

export const phasesTime: Record<Phase, number> = {
  playersThinking: 4000,
  break: 1500,
  onePlayerChooseMove: 2000,
  twoPlayersChooseMove: 2000,
  playersMadeReveal: 2000,
  playerAWonRound: 1000,
  done: 1000,
};

export const generatePhaseSequence = (
  start: { phase: Phase; round: number },
  end: { phase: Phase; round: number }
): Array<{ phase: Phase; round: number }> => {
  const startTotal =
    (start.round - 1) * PhaseArray.length + PhaseArray.indexOf(start.phase);
  const endTotal =
    (end.round - 1) * PhaseArray.length + PhaseArray.indexOf(end.phase);
  const phasesNeeded = endTotal - startTotal + 1;

  if (phasesNeeded <= 0) return [];

  return Array.from({ length: phasesNeeded }, (_, index) => {
    const totalPhase = startTotal + index + 1;
    const round = Math.ceil(totalPhase / PhaseArray.length);
    const phaseIndex = (totalPhase - 1) % PhaseArray.length;
    const phase = PhaseArray[phaseIndex];

    return { phase, round };
  });
};

interface AnimationPhase {
  phase: Phase;
  round: number;
  duration: number;
}

interface AnimationState {
  currentPhase: Phase;
  currentRound: number;
  isDone: boolean;
  queue: AnimationPhase[];
  isProcessing: boolean;
}

interface AnimationStore extends AnimationState {
  addToQueue: (phases: AnimationPhase[]) => void;
  clearQueue: () => void;
  processNextAnimation: () => Promise<void>;
  handleExternalTransition: (desiredPhase: Phase) => void;
  manageQueue: ({ phase, round }: { phase: Phase; round: number }) => void;
}

export const useStreamStore = create<AnimationStore>((set, get) => ({
  currentPhase: PhaseArray[0], // 'playersThinking'
  currentRound: 1,
  isDone: true,
  queue: [],
  isProcessing: false,

  addToQueue: (phases: AnimationPhase[]) => {
    set((state) => {
      const newPhases = phases.filter((phase) => {
        return !state.queue.some(
          (queuedPhase) =>
            queuedPhase.phase === phase.phase &&
            queuedPhase.round === phase.round
        );
      });

      if (newPhases.length > 0) {
        return { queue: [...state.queue, ...newPhases] };
      }
      return {};
    });

    const existingPhasesCount = phases.length;
    const newPhasesCount = phases.filter((phase) => {
      const state = useStreamStore.getState();
      return !state.queue.some(
        (queuedPhase) =>
          queuedPhase.phase === phase.phase && queuedPhase.round === phase.round
      );
    }).length;

    console.log(
      `Added ${newPhasesCount} new phases to the queue out of ${existingPhasesCount}.`
    );

    if (!get().isProcessing) {
      get().processNextAnimation();
    }
  },

  clearQueue: () => {
    set({
      queue: [],
      isProcessing: false,
      currentPhase: PhaseArray[0],
      currentRound: 1,
      isDone: true,
    });
    console.log('Animation queue cleared and state reset.');
  },

  processNextAnimation: async () => {
    const state = get();
    if (state.queue.length === 0) {
      set({ isProcessing: false });
      console.log('No more animations in the queue.');
      return;
    }

    set({ isProcessing: true });
    const nextAnimation = state.queue[0];

    set({
      currentPhase: nextAnimation.phase,
      currentRound: nextAnimation.round,
      isDone: false,
    });

    console.log(
      `Processing Round ${nextAnimation.round}, Phase '${nextAnimation.phase}'`
    );

    // Simulate animation duration
    await new Promise((resolve) => setTimeout(resolve, nextAnimation.duration));

    set((state) => ({
      isDone: true,
      queue: state.queue.slice(1),
    }));
    get().queue;
    console.log(
      `Completed Round ${nextAnimation.round}, Phase '${nextAnimation.phase}'`
    );
    get().processNextAnimation();
  },

  manageQueue: ({ phase, round }) => {
    let lastAnimation = get().queue[get().queue.length - 1] ?? {
      phase: get().currentPhase,
      round: get().currentRound,
    };
    const initialPhaseSequence = generatePhaseSequence(
      lastAnimation,
      { phase, round }
    );

    console.log('initialPhaseSequence', initialPhaseSequence);

    const initialAnimationPhases: AnimationPhase[] = initialPhaseSequence.map(
      (seq) => ({
        phase: seq.phase,
        round: seq.round,
        duration: phasesTime[seq.phase],
      })
    );
    get().addToQueue(initialAnimationPhases);
  },
  handleExternalTransition: (desiredPhase: Phase) => {
    const { currentPhase, currentRound } = get();

    const currentPhaseIndex = PhaseArray.indexOf(currentPhase);
    const desiredPhaseIndex = PhaseArray.indexOf(desiredPhase);

    if (desiredPhaseIndex === currentPhaseIndex + 1) {
      const animationPhase: AnimationPhase = {
        phase: desiredPhase,
        round: currentRound,
        duration: phasesTime[desiredPhase],
      };

      set((state) => ({
        queue: [animationPhase, ...state.queue], // Prepend to process immediately
      }));

      console.log(
        `External transition to phase '${desiredPhase}' for Round ${currentRound}`
      );
      if (!get().isProcessing) {
        get().processNextAnimation();
      }
    } else {

      const animationPhase: AnimationPhase = {
        phase: desiredPhase,
        round: currentRound,
        duration: phasesTime[desiredPhase],
      };

      set((state) => ({
        queue: [...state.queue, animationPhase],
      }));
      console.warn(
        `Enqueued out-of-sequence phase '${desiredPhase}' for Round ${currentRound}`
      );
    }
  },
}));
