import { useState, useEffect, useRef, useMemo } from 'react';
import { getBlockNumber } from '@wagmi/core';
import { config } from 'shared/config';
import { useAccount, usePublicClient } from 'wagmi';
import { decodeEventLog } from 'viem';
import contract from 'shared/contract/contract.abi.json';
import { RoomStatus } from '@/shared/types/app-types';
import { useCurrenciesStore } from '@/shared/store/currenciesStore';

const contractAddress = import.meta.env.VITE_RSP_CONTRACT_ADDRESS;

export const useBlockToFindCreateAndJoin = () => {
  const startBlockNumber = useRef<bigint | null>(null);
  const [filteredRooms, setFilteredRooms] = useState<any[]>([]);
  const [blockNumber, setBlockNumber] = useState<bigint>(0n);
  const [time, setTime] = useState<number>(0);
  const { chainId } = useAccount();
  const publicClient = usePublicClient({
    config,
  });
  const { availableCurrencies } = useCurrenciesStore();
  useEffect(() => {
    const fetchBlockNumber = async () => {
      let block = await getBlockNumber(config, {
        chainId: chainId as 1 | 42161 | 137 | 8453 | undefined,
        cacheTime: 0,
      });
      startBlockNumber.current = block - 1n;
    };

    fetchBlockNumber();
  }, [chainId]);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const fetchRoomEvents = async () => {
    try {
      const blockNumber = await getBlockNumber(config, {
        chainId: chainId as 1 | 42161 | 137 | 8453 | undefined,
        cacheTime: 0,
      });
      setBlockNumber(blockNumber);
      let fromBlock;
      let toBlock;
      if (startBlockNumber.current && startBlockNumber.current >= blockNumber) {
        return;
      }
      if (startBlockNumber.current) {
        fromBlock = startBlockNumber.current + 1n;
        toBlock = blockNumber;
      } else {
        fromBlock = blockNumber - 1n;
        toBlock = blockNumber;
      }
      startBlockNumber.current = blockNumber;
      setTime(Date.now());

      const logs = await publicClient.getLogs({
        fromBlock,
        toBlock,
        address: contractAddress,
      });

      const decodedLogs = logs.map((log) => {
        try {
          return decodeEventLog({
            abi: contract,
            data: log.data,
            topics: log.topics,
          });
        } catch (error) {
          console.error('Error decoding log:', error);
          return null;
        }
      });

      const filteredDecodedLogs = decodedLogs
        .filter(
          (event) =>
            event !== null &&
            (event.eventName === RoomStatus.ROOM_CLOSED ||
              event.eventName === RoomStatus.ROOM_CREATED ||
              event.eventName === RoomStatus.ROOM_JOINED)
        )
        .map((item: any) => {
          if (item.eventName === RoomStatus.ROOM_CLOSED) {
            return {
              playerAddress: item.args.receiver,
              amount: item.args.pot,
              status: item.eventName,
              roomId: item.args.roomId,
            };
          }
          if (item.eventName === RoomStatus.ROOM_CREATED) {
            const roomCurrency = availableCurrencies.find(
              (currency) =>
                currency.address.toLowerCase() ===
                item.args.currency.toLowerCase()
            );
            const roomBet =
              Number(item.args.betAmount) / 10 ** roomCurrency!.decimals;

            return {
              playerAddress: item.args.entrantA,
              currency: roomCurrency,
              amount: roomBet,
              status: item.eventName,
              roomId: item.args.roomId,
            };
          }
          if (item.eventName === RoomStatus.ROOM_JOINED) {
            return {
              roomId: item.args.roomId,
              status: item.eventName,
            };
          }
        });

      setFilteredRooms(filteredDecodedLogs);
    } catch (error) {
      console.error('Error fetching or decoding logs:', error);
    }
  };
  useEffect(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    intervalRef.current = setInterval(fetchRoomEvents, 2000);

    return () => {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [chainId]);

  const roomsToAdd = useMemo(() => {
    return filteredRooms.filter(
      (item) => item.status === RoomStatus.ROOM_CREATED
    );
  }, [filteredRooms]);

  const roomsToRemove = useMemo(() => {
    return filteredRooms.filter(
      (item) =>
        item.status === RoomStatus.ROOM_CLOSED ||
        item.status === RoomStatus.ROOM_JOINED
    );
  }, [filteredRooms]);

  return {
    roomsToAdd,
    roomsToRemove,
    blockNumber,
    time,
  };
};
