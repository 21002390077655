import UsdtIcon from 'assets/coins/usdt.svg?react';
import UsdcIcon from 'assets/coins/usdc.svg?react';
import ArbitrumIcon from 'assets/coins/arb.svg?react';
import BonkIcon from 'assets/coins/bonk.svg?react';
import DaiIcon from 'assets/coins/dai.svg?react';
import LinkIcon from 'assets/coins/link.svg?react';
import PendleIcon from 'assets/coins/pendle.svg?react';
import WbtcIcon from 'assets/coins/wbtc.svg?react';
import AeroIcon from 'assets/coins/aero.svg?react';
import ApeIcon from 'assets/coins/ape.svg?react';
import BlurIcon from 'assets/coins/blur.svg?react';
import BondIcon from 'assets/coins/bond.svg?react';
import BrettIcon from 'assets/coins/brett.svg?react';
import DegenIcon from 'assets/coins/degen.svg?react';
import FriendIcon from 'assets/coins/friend.svg?react';
import PepeIcon from 'assets/coins/pepe.svg?react';
import SandIcon from 'assets/coins/sand.svg?react';
import ShibIcon from 'assets/coins/shib.svg?react';
import WethIcon from 'assets/coins/weth.svg?react';
import WmaticIcon from 'assets/coins/wmatic.svg?react';

export const coinIcons = {
  USDT: UsdtIcon,
  USDC: UsdcIcon,
  ARB: ArbitrumIcon,
  BONK: BonkIcon,
  DAI: DaiIcon,
  LINK: LinkIcon,
  PENDLE: PendleIcon,
  WBTC: WbtcIcon,
  AERO: AeroIcon,
  APE: ApeIcon,
  BLUR: BlurIcon,
  BOND: BondIcon,
  BRETT: BrettIcon,
  DEGEN: DegenIcon,
  FRIEND: FriendIcon,
  PEPE: PepeIcon,
  SAND: SandIcon,
  SHIB: ShibIcon,
  WETH: WethIcon,
  WMATIC: WmaticIcon,
};