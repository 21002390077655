import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAccount, useDisconnect } from 'wagmi';
import logo from 'assets/png/logo.png';
import IconTelegram from 'assets/telegram.svg?react';
import IconX from 'assets/x.svg?react';
import IconDiscord from 'assets/discord.svg?react';
import LogoutIcon from 'assets/logout.svg?react';
import CloseXIcon from 'assets/closeX.svg?react';
import GridBackground from 'shared/components/GridBackground/GridBackground';
import CustomWalletButton from 'shared/components/CustomWalletButton/CustomWalletButton';
import SocialMediaBtn from 'shared/components/SocialMediaBtn/SocialMediaBtn';
import useMediaQuery from 'shared/hooks/useMediaQuery';
import { BurgerMenu } from 'shared/components/burger-menu';
import { useRoomStore } from 'shared/store/roomStore';
import useLocalStorageCheck from 'shared/hooks/useLocalStorageCheck';
import NotWorkingLocalStorageModal from 'shared/components/NotWorkingLocalStorageModal/NotWorkingLocalStorageModal';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import MobileDropDown from 'modules/Room/mobile/MobileDropDown/MobileDropDown';
import PlaySequence from 'modules/ConnectPage/components/PlaySequence/PlaySequence';
import {
  ChainsSwitcher,
  MobileWalletRedirectModal,
} from 'modules/ConnectPage/components';
import { SOCIAL_MEDIA } from 'shared/constants/socialMedia';
import { Wallet, walletsConfig } from './constants';
import { useGlobalStore } from 'shared/store/globalStore';
import 'modules/ConnectPage/page/rainbowkit-styles.scss';
import './ConnectPage.scss';
import { isRedirectAvailable } from 'modules/ConnectPage/helpers/isRedirectAvailable';

const MobileConnectPage = () => {
  const isLocalStorageAvailable = useLocalStorageCheck();
  const [menuOpen, setMenuOpen] = React.useState(false);
  const { isConnected, address } = useAccount();
  const { t } = useTranslation();
  const { disconnectAsync } = useDisconnect();
  const { setPlayerAAddress } = useRoomStore();
  const [isRedirectMobileWalletModalOpen, setIsRedirectMobileWalletModalOpen] =
    useState(false);
  const [selectedWallet, setSelectedWallet] = useState<Wallet>();
  const appUrl = import.meta.env.VITE_RPS_APP_URL;

  const { isMobileBannerClosed, setIsMobileBannerClosed } = useGlobalStore();

  useEffect(() => {
    if (isConnected) setPlayerAAddress(address?.toLowerCase() as `0x${string}`);
  }, [isConnected]);

  const handleOutsideClick = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      setMenuOpen(false);
    }
  };
  const menuRef = React.useRef<HTMLDivElement>(null);
  useEffect(() => {
    if (menuOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [menuOpen]);
  const socialMediaClick = (url: string) => () => {
    window.open(url, '_blank');
  };

  const disconnectHandler = () => {
    disconnectAsync();
    setTimeout(() => {
      disconnectAsync();
    }, 100);
    setMenuOpen(false);
  };

  const isCustomConnectPossible = isRedirectAvailable(window.navigator);

  const onCustomConnect = (wallet: Wallet) => {
    if (!isCustomConnectPossible) {
      return;
    }

    if (wallet === Wallet.METAMASK) {
      return window.open(
        `https://metamask.app.link/dapp/${appUrl}?isAutoConnectAvailable=true`
      );
    }

    if (wallet === Wallet.COINBASE) {
      return window.open(`https://go.cb-w.com/dapp?cb_url=${appUrl}`);
    }

    if (wallet === Wallet.PHANTOM) {
      return window.open(
        `https://phantom.app/ul/browse/${appUrl}?isAutoConnectAvailable=true`
      );
    }

    setSelectedWallet(wallet);
    setIsRedirectMobileWalletModalOpen(true);
  };

  const handleCloseWalletRedirectModal = () => {
    setIsRedirectMobileWalletModalOpen(false);
  };

  const handleCloseBanner = () => {
    setIsMobileBannerClosed(true);
  };

  const isBannerShown =
    isCustomConnectPossible &&
    useMediaQuery('(max-width: 768px)') &&
    !isMobileBannerClosed;

  return (
    <div className="flex items-center flex-col h-screen overflow-auto">
      {isBannerShown && (
        <div className="text-white bg-yellow font-bold text-xs p-2 [&>svg]:w-[24px] [&>svg]:h-[24px] [&>svg]:cursor-pointer flex justify-between items-center">
          <div className="w-4/5">{t('common.banner')}</div>
          <CloseXIcon onClick={handleCloseBanner} />
        </div>
      )}
      <div className="pt-[8px] px-[16px] pb-[18px] bg-background w-full">
        <header className="flex justify-between items-center mb-[32px]">
          <img
            className="h-[50px]"
            src={logo}
            alt={'rock paper scissors logo'}
          />

          <MobileDropDown
            open={menuOpen}
            ref={menuRef}
            list={[
              {
                label: 'Logout',
                value: 'logout',
                svg: <LogoutIcon className="h-[25px] w-[25px] ml-[10px] " />,
              },
            ]}
            onClick={disconnectHandler}
            position={{ top: 155, right: 50 }}
          />
          <BurgerMenu />
        </header>
        <AnimatedTransition show={!isConnected}>
          <h3 className="text-[24px] font-black text-yellow-text uppercase">
            {t('common.connectWallet')} <br />
            <span className="text-white">{t('common.playTheGame')}</span>
          </h3>
        </AnimatedTransition>
        <AnimatedTransition show={isConnected}>
          <h3 className="text-[24px] font-black text-yellow-text uppercase">
            {t('common.select')}{' '}
            <span className="text-white">{t('common.network')}</span>
          </h3>
        </AnimatedTransition>
      </div>
      <GridBackground className="p-[32px]">
        <PlaySequence isConnected={isConnected} />
      </GridBackground>
      <div className="pt-[18px] w-full px-[16px] pb-[32px] bg-background">
        <p className="text-simple-text text-[11px] leading-[140%]">
          {isConnected
            ? t('common.chooseBlockchainNetwork')
            : t('common.gameWeb3')}
        </p>
      </div>
      <AnimatedTransition show={!isConnected}>
        <div className="mx-[16px] mt-[24px] py-[24px] px-[42px] border border-border rounded-[8px] flex flex-col items-center mb-[24px]">
          <p className="capitalize text-[12px] font-bold text-yellow-text mb-[20px]">
            {t('common.connect')}{' '}
            <span className="text-white">{t('common.aWallet')}</span>
          </p>
          <div className="w-full grid grid-cols-3 gap-4">
            {walletsConfig.map((item) => {
              return (
                <CustomWalletButton
                  key={item.wallet}
                  wallet={item.wallet}
                  Icon={item.icon}
                  name={item.name}
                  isCustomConnectPossible={isRedirectAvailable(
                    window.navigator
                  )}
                  onCustomConnect={() => onCustomConnect(item.wallet)}
                />
              );
            })}
          </div>
        </div>
        <h3 className="text-yellow-text text-[24px] fюont-black mb-[12px] mx-[16px]">
          {t('common.what')}{' '}
          <span className="text-white">{t('common.aWallet')}</span>
        </h3>
        <p className="text-simple-text text-[11px] leading-[140%] mx-[16px] mb-[24px]">
          {t('common.whatWalletIs')}
        </p>
      </AnimatedTransition>
      <AnimatedTransition show={isConnected} asFragment>
        <div className="py-[12px] w-full px-[16px]">
          <ChainsSwitcher />
        </div>
      </AnimatedTransition>
      <div className="pt-[32px] w-full px-[16px] pb-[20px] bg-background">
        <h3 className="text-[24px] mb-[16px] font-black text-yellow-text uppercase">
          {t('common.join')}{' '}
          <span className="text-white">{t('common.community')}</span>
        </h3>
        <p className="text-simple-text text-[11px] leading-[140%] ">
          {t('common.subscribeToOurSocialNetwork')}
        </p>
      </div>
      <GridBackground className="px-[16px] py-[24px]">
        <div className="flex w-full justify-around">
          <SocialMediaBtn
            onClick={socialMediaClick(SOCIAL_MEDIA.TELEGRAM)}
            svg={<IconTelegram className='w-[30px] h-[24px]' />}
          />
          <SocialMediaBtn
            onClick={socialMediaClick(SOCIAL_MEDIA.TWITTER)}
            svg={<IconX />}
          />
          <SocialMediaBtn
            onClick={socialMediaClick(SOCIAL_MEDIA.DISCORD)}
            svg={<IconDiscord className='w-[33px] h-[24px]'/>}
          />
        </div>
      </GridBackground>
      <div className="pt-[32px] px-[16px] pb-[16px] bg-background">
        <p className="text-gray text-[11px] leading-[140%] ">
          <span className="block mb-2">{t('common.disclaimer')}</span>
          <span className="block  mb-2"> {t('common.disclaimer2')}</span>
          <span className="block mb-2"> {t('common.disclaimer3')}</span>
          {t('common.disclaimer4')}
        </p>
      </div>

      {isRedirectMobileWalletModalOpen && selectedWallet ? (
        <MobileWalletRedirectModal
          wallet={selectedWallet}
          onClose={handleCloseWalletRedirectModal}
        />
      ) : null}
      <AnimatedTransition show={!isLocalStorageAvailable}>
        <NotWorkingLocalStorageModal open={!isLocalStorageAvailable} />
      </AnimatedTransition>
    </div>
  );
};

export default MobileConnectPage;
