import React, { FunctionComponent, SVGProps } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface MenuItemProps {
  icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  text: string;
  /** If provided, we'll treat this as a link (internal or external) */
  href?: string;
  /** If true and href is provided, open an external link instead of a React Router link */
  external?: boolean;
  /** If you need to attach onClick for either link or button */
  onClick?: () => void;
  className?: string;
  last?: boolean;
  orange?: boolean;
}

/**
 * MenuItem can be:
 * 1) External link: (external = true)
 * 2) Internal link: (external = false)
 * 3) Button:        (no href given)
 */
export const MenuItem: React.FC<MenuItemProps> = ({
  icon: Icon,
  text,
  href,
  external,
  onClick,
  className,
  last,
  orange
}) => {
  const { t } = useTranslation();

  // Common content for all variants
  const content = (
    <>
      <Icon className={`${orange ? 'text-orange-btn' : 'text-gray'} w-[20px] h-[20px] md:w-[28px] md:h-[28px]`} />
      <span className={`${orange ? 'text-orange-btn' : ''}`}>{t(text)}</span>
    </>
  );
  return (
    <li
      className={`${
        last ? '' : 'border-b'
      } py-[16px] md:py-[26px] border-border-menu ${className ?? ''}`}
    >
      {href && external && (
        <a
          href={href}
          target='_blank'
          rel="noopener noreferrer"
          onClick={onClick}
          className="text-white md:text-[20px] font-[600] flex items-center gap-2.5 md:gap-5"
        >
          {content}
        </a>
      )}
      {href && !external && (
        <Link
          to={href}
          onClick={onClick}
          className="text-white md:text-[20px] font-[600] flex items-center gap-2.5 md:gap-5"
        >
          {content}
        </Link>
      )}
      {!href && (
        <button
          type="button"
          onClick={onClick}
          className="text-white md:text-[20px] font-[600] flex items-center gap-2.5 md:gap-5"
        >
          {content}
        </button>
      )}
    </li>
  );
};
