import React, { useEffect, useRef, useState } from 'react';
import humanYou from 'assets/png/human-you.png';
import { useTranslation } from 'react-i18next';

interface NoTableImageProps {
  noSearchResults: boolean;
}

export const NoTableImage = ({ noSearchResults }: NoTableImageProps) => {
  const { t } = useTranslation();
  const imageRef = useRef<HTMLImageElement>(null);
  const [imageWidth, setImageWidth] = useState(100);

  const updateWidth = () => {
    if (imageRef.current) {
      setImageWidth(imageRef.current.offsetWidth);
    }
  };

  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => window.removeEventListener('resize', updateWidth);
  }, []);

  return (
    <>
      <img
        src={humanYou}
        alt="you"
        className="h-[80%] absolute bottom-0  sm:transform-none transform -translate-x-1/2 left-1/2 sm:left-0"
        ref={imageRef}
        onLoad={updateWidth}
      />
      <div className="absolute w-[826px] h-[816px] left-[-90px] top-[240px] bg-[rgba(255,192,0,0.5)] opacity-30 filter blur-[128px]" />
      <div
        className="px-5 sm:px-0 mt-4 sm:mt-0 sm:absolute top-1/2 sm:transform sm:-translate-y-1/2"
        style={{ left: `${imageWidth + 50}px` }}
      >
        {noSearchResults ? (
          <>
            <p className="text-white font-black text-[24px] sm:text-[56px] xlg:text-[64px] xlg:leading-[110%] uppercase">
              {t('pages.liveGamesSection.noGames')}
            </p>
            <p className="text-bold text-white text-[12px] sm:text-[28px] max-w-[394px]">
              <span className="opacity-70">
                {t('pages.liveGamesSection.chosen')}
              </span>
            </p>
          </>
        ) : (
          <>
            <p className="text-white font-black text-[24px] sm:text-[56px] xlg:text-[64px] xlg:leading-[110%] uppercase">
              {t('pages.liveGamesSection.timeTo')}{' '}
              <span className="text-yellow-text">
                {t('pages.liveGamesSection.act')}
              </span>
            </p>
            <p className="text-bold text-white text-[12px] sm:text-[28px] max-w-[394px]">
              <span className="opacity-70">
                {t('pages.liveGamesSection.first')}{' '}
              </span>
              {t('pages.liveGamesSection.you')}
              <span className="opacity-70">
                {t('pages.liveGamesSection.take')}
              </span>
            </p>
          </>
        )}
      </div>
    </>
  );
};
