import { NULL_ADDRESS } from 'shared/constants/constants';
import {
  CloserEnum,
  MovesEnum,
  RoomStatusEnum,
  RoundStagesEnum,
  RoundWinnerOutcomes,
  WinnerStatusEnum,
} from 'shared/constants/shared-enums';
import { PlayerType } from 'shared/types/app-types';
import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import {
  readRoom,
  readAllRoomRounds,
} from 'shared/viem-functions/read-game-function';
import { PublicClient } from 'viem';
import {
  parseRoomData,
  parseRounds,
  shouldUpdateRounds,
  roomsAreEqual,
  scoreCounter,
} from './room-utils';

interface roundMove {
  move: MovesEnum;
  encrMove: `0x${string}`;
}

export interface RoomData {
  roomId: number | null; // room id 'rooms'
  playerA: PlayerType; // address, wins 'rooms'
  playerB: PlayerType; // address, wins 'rooms'
  bet: number | null; // bet amount, pot is bet * 2 'rooms'
  token: `0x${string}`; // token address 'rooms'
  roomStatus: RoomStatusEnum; // room state 'rooms'
  winsTo: number; // wins to win the game 'rooms'
  roundCounter: number; // round number 'rooms'
  closer: CloserEnum; // closer 'rooms'
  deadline: bigint; // deadline 'rooms'
  winnerStatus: WinnerStatusEnum; // winner status 'rooms'
}

export interface RoundData {
  playerA: roundMove;
  playerB: roundMove;
  winner: RoundWinnerOutcomes;
  stage: number;
  roundStage: RoundStagesEnum;
}

interface winsData {
  winsPlayerA: number;
  winsPlayerB: number;
}

interface State {
  room: RoomData;
  rounds: RoundData[] | null;
  wins: winsData;
}

interface Actions {
  startStopRoom: ({
    roomId,
    provider,
  }: {
    roomId: number | null;
    provider: PublicClient | null;
  }) => void;
}

let intervalId: ReturnType<typeof setInterval> | null = null;

const initialState: State = {
  room: {
    roomId: null,
    playerA: {
      address: NULL_ADDRESS,
      wins: 0n,
    },
    playerB: {
      address: NULL_ADDRESS,
      wins: 0n,
    },
    bet: null,
    token: NULL_ADDRESS,
    roomStatus: RoomStatusEnum.None,
    winsTo: 3,
    roundCounter: 1,
    closer: CloserEnum.None,
    deadline: 0n,
    winnerStatus: WinnerStatusEnum.None,
  },
  rounds: null,
  wins: {
    winsPlayerA: 0,
    winsPlayerB: 0,
  },
};

export const useNewRoomStore = create<State & Actions>()(
  immer(
    devtools((set, getState) => ({
      ...initialState,

      startStopRoom: ({ roomId, provider }) => {
        if (roomId === null && intervalId) {
          clearInterval(intervalId);
          intervalId = null;
          return;
        }

        if (roomId && provider) {
          if (intervalId) {
            clearInterval(intervalId);
            intervalId = null;
          }

          intervalId = setInterval(() => {
            (async () => {
              try {
                // 1. Read room data
                const room = await readRoom({
                  provider,
                  roomId: BigInt(roomId),
                });
                const parsedRoom = parseRoomData(room);
                console.log('Parsed room:', parsedRoom);
                // 2. Compare old vs new
                const currentStore = getState();
                if (!roomsAreEqual(parsedRoom, currentStore.room)) {
                  // 3. Update the store if changed
                  set((draft) => {
                    draft.room = parsedRoom;
                  });
                  // 4. Fetch Rounds
                  const rounds = await readAllRoomRounds({
                    provider,
                    roomId: BigInt(roomId),
                  });
                  const parsedRounds = parseRounds(rounds);
                  console.log('Parsed rounds:', parsedRounds);
                  const storeAfterRoomUpdate = getState();
                  const existingRounds = storeAfterRoomUpdate.rounds;
                  const isDifferent = shouldUpdateRounds(
                    existingRounds,
                    parsedRounds
                  );

                  if (isDifferent) {
                    let wins = scoreCounter(parsedRounds);
                    if (
                      wins.winsPlayerA !== currentStore.wins.winsPlayerA ||
                      wins.winsPlayerB !== currentStore.wins.winsPlayerB
                    ) {
                      set((draft) => {
                        draft.wins = wins;
                      });
                    }
                    set((draft) => {
                      draft.rounds = parsedRounds;
                    });
                  }
                  // 5. If closed => stop polling
                  if (parsedRoom.roomStatus === RoomStatusEnum.Closed) {
                    if (intervalId) clearInterval(intervalId);
                    intervalId = null;
                    return;
                  }
                }
              } catch (err) {
                console.error('Error in startStopRoom interval:', err);
              }
            })();
          }, 3000);
        }
      },
    }))
  )
);
