import { arbitrum, mainnet, polygon, base } from '@wagmi/core/chains';
import IconArbitrum from 'assets/networks/arbitrum.svg?react';
import IconEthereum from 'assets/networks/ethereum.svg?react';
import IconPolygon from 'assets/networks/polygon.svg?react';
import IconPolygonMumbai from 'assets/networks/polygon.svg?react';
import IconBaseSepolia from 'assets/networks/baseSepolia.svg?react';
import IconUnknown from 'assets/networks/unknown.svg?react';
import {
  fallback,
  unstable_connector,
  injected,
  http,
} from '@wagmi/core';
import { connectorsForWallets } from '@rainbow-me/rainbowkit';
import {
  metaMaskWallet,
  rainbowWallet,
  walletConnectWallet,
  coinbaseWallet,
  phantomWallet,
  trustWallet,
} from '@rainbow-me/rainbowkit/wallets';
import { createConfig } from 'wagmi';

const connectors = connectorsForWallets(
  [
    {
      groupName: 'Recommended',
      wallets: [
        metaMaskWallet,
        rainbowWallet,
        walletConnectWallet,
        coinbaseWallet,
        phantomWallet,
        trustWallet,
      ],
    },
  ],
  {
    appName: 'RSP App',
    projectId: import.meta.env.VITE_WALLET_CONNECT_PROJECT_ID,
  }
);

export const config = createConfig({
  chains: [arbitrum, mainnet, polygon, base],
  connectors,
  transports: {
    [arbitrum.id]: fallback([
      unstable_connector(injected),
      http(undefined, {
        batch: {
          batchSize: 2_000,
          wait: 12000,
        },
        retryCount: 5,
        retryDelay: 12000,
        timeout: 60_000,
      }),
    ]),
    [mainnet.id]: fallback([
      unstable_connector(injected),
      http(undefined, {
        batch: {
          batchSize: 2_000,
          wait: 12000,
        },
        retryCount: 5,
        retryDelay: 12000,
        timeout: 60_000,
      }),
    ]),
    [polygon.id]: fallback([
      unstable_connector(injected),
      http(undefined, {
        batch: {
          batchSize: 2_000,
          wait: 12000,
        },
        retryCount: 5,
        retryDelay: 12000,
        timeout: 60_000,
      }),
    ]),
    [base.id]: fallback([
      unstable_connector(injected),
      http(undefined, {
        batch: {
          batchSize: 2_000,
          wait: 12000,
        },
        retryCount: 5,
        retryDelay: 12000,
        timeout: 60_000,
      }),
    ]),
  },
});

export const CHAINS = {
  mainnet,
  arbitrum,
  polygon,
  base,
};

export const CHAINS_IDS = [mainnet.id, arbitrum.id, polygon.id, base.id];

export const CHAINS_LOGOS = {
  [arbitrum.id]: <IconArbitrum className="w-8 h-8" />,
  [mainnet.id]: <IconEthereum className="w-8 h-8" />,
  [base.id]: <IconBaseSepolia className="w-8 h-8" />,
  [polygon.id]: <IconPolygonMumbai className="w-8 h-8" />,
};
