import 'shared/utils/setntry-instrument.js';
import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import 'shared/i18n/i18n';
import { Web3WagmiConfig } from 'shared/web3/components/web3-wagmi-config/Web3WagmiConfig';
import App from './app/App';
import '@rainbow-me/rainbowkit/styles.css';
// import ReactPixel from 'react-facebook-pixel';

const container = document.getElementById('root');
const root = createRoot(container as HTMLElement);


// const pixelId = '1494392224593363';
// ReactPixel.init(pixelId);
// ReactPixel.pageView();

root.render(
  <StrictMode>
    <Web3WagmiConfig>
      <Router>
        <App />
      </Router>
    </Web3WagmiConfig>
  </StrictMode>
);
