import { Avatar } from 'shared/components/Avatar/Avatar';
import { NULL_ADDRESS } from 'shared/constants/constants';
import Heart from 'assets/heart.svg?react';
import HeartEmpty from 'assets/heart-empty.svg?react';
import classNames from 'classnames';
import './player-card.css';
import useMediaQuery from '@/shared/hooks/useMediaQuery';

interface Props {
  name: string;
  playerAddress: `0x${string}`;
  position: string;
  wins: number;
}

export const PlayerCard = ({ playerAddress, position, name, wins }: Props) => {
  const isTablet = useMediaQuery('(max-width: 1200px)');

  const getRemainingWins = () => {
    return 3 - wins;
  };
  const remainingWins = getRemainingWins();

  return (
    <div
      className={`
        flex flex-col w-full h-full max-w-[342px] max-h-[258px] items-center lg:py-4 rounded-[24px] 
        lg:border lg:border-border transition lg:bg-opacity-30 lg:bg-background 
        justify-between relative lg:watch-game-card 
      `}
    >
      <div className="flex flex-col items-center">
        <div className="flex flex-row lg:mb-[10px]">
          {playerAddress !== NULL_ADDRESS ? (
            <Avatar
              userAddress={playerAddress}
              className={`w-[54px] sm:w-[64px] h-[54px] sm:h-[64px] rounded-[8px] rounded-b-[0] lg:rounded-[12px]`}
            />
          ) : (
            <div className="w-[54px] sm:w-[64px] h-[54px] sm:h-[64px] flex justify-center items-center rounded-[12px] bg-deep-blue">
              <span className="text-blue text-[58px]">?</span>
            </div>
          )}
        </div>

        <div className="flex lg:block background-dark-background border border-border rounded-t-[0] rounded-b-lg lg:rounded-lg px-[8px] sm:px-[4px] lg:px-[32px] lg:py-[3px]">
          <span
            className="text-[#6A6B8A] lg:text-yellow text-[8px] sm:text-[12px] lg:text-[20px]"
            style={{ opacity: playerAddress === NULL_ADDRESS ? 0 : 1 }}
          >
            {playerAddress.substring(0, 6)}......
            {!isTablet ? playerAddress.substring(playerAddress.length - 4) : null}
          </span>
        </div>

        <div
          className="background-dark-background border border-border rounded-tl-none rounded-tr-none rounded-bl-lg rounded-br-lg px-[32px] py-[3px] border-t-0 hidden lg:flex">
          <span className="text-gray text-[14px]">{name}</span>
        </div>

        <div
          className={classNames(
            'flex gap-[3px] lg:gap-4 text-white mt-[8px] lg:mt-[24px]',
            remainingWins !== null
              ? 'animate-fast-appear'
              : '',
            position === 'order-3' ? 'transform scale-x-[-1]' : '',
          )}>
          {remainingWins !== null &&
            Array.from({ length: remainingWins }).map((_, index) => (
              <Heart key={`heart-${index}`} className="w-[16px] h-[15px] lg:w-[66px] lg:h-[60px]" />
            ))
          }
          {remainingWins !== null &&
            Array.from({ length: 3 - remainingWins }).map((_, index) => (
              <HeartEmpty key={`heart-empty-${index}`} className="w-[16px] h-[15px] lg:w-[66px] lg:h-[60px]" />
            ))
          }
        </div>
      </div>
    </div>
  );
};
