import React, { ButtonHTMLAttributes } from 'react';

interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
}
export const MediaBtn = ({ children, className, ...props }: Props) => {
  return (
    <button
      className={`w-full text-white-text flex items-center justify-center gap-4 py-3 md:py-6 text-[16px] rounded-[12px] border border-[#3D557A] ${className ?? ''}`}
      {...props}
    >
      {children}
    </button>
  );
}

