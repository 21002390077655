import { useMediaQuery } from 'usehooks-ts';
import { DesktopView, MobileView } from './views';
import React, { useEffect } from 'react';
import { ROUTES } from 'shared/constants';
import { useNavigate } from 'react-router-dom';
import { useAccount } from 'wagmi';
import { SignModal } from 'modules/ConnectPage/components/SignModal/SignModal';
import AnimatedTransition from 'shared/components/AnimatedTransition/AnimatedTransition';
import { useShowSignModal } from 'shared/hooks/useShowSignModal';
import { useActiveGameRedirect } from '@/modules/Room/Hooks/useActiveGameRedirect';

export const LobbyPage = () => {
  const isMobile = useMediaQuery('(max-width: 768px)');
  const { isConnected, chainId } = useAccount();
  const navigate = useNavigate();
  useActiveGameRedirect();
  const { showSignModal } = useShowSignModal();
  useEffect(() => {
    if (!isConnected) {
      navigate(ROUTES.HOME);
    }
  }, [isConnected]);

  return (
    <>
      <AnimatedTransition show={showSignModal}>
        <SignModal />
      </AnimatedTransition>
      {isMobile ? <MobileView key={chainId} /> : <DesktopView key={chainId} />}
    </>
  );
};
