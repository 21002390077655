import React, { useEffect, useRef, useState } from 'react';
import classNames from 'classnames';
import { useRoomStore } from 'shared/store/roomStore';
import { PlayerCard } from 'modules/Room/features/PlayerCard/PlayerCard';
import HistoryMoves from 'modules/Room/features/HistoryMoves/HistoryMoves';
import Score from 'modules/Room/features/Score/Score';
import RoomMenu from 'modules/Room/features/RoomMenu/RoomMenu';
import floor from 'assets/png/floor.png';
import humanLeft from 'assets/png/human-left.png';
import humanRight from 'assets/png/human-right.png';
import useCreateJoinRoomEvents from 'modules/Room/Hooks/useCreateJoinRoomEvents';
import { NULL_ADDRESS } from 'shared/constants/constants';
import { useParams } from 'react-router-dom';
import { RoomStatusEnum } from 'shared/constants/shared-enums';
import { RoomBox } from 'modules/Room/features/room-box/room-box';

const DesktopRoom = () => {
  useCreateJoinRoomEvents();
  const { activeRoomId } = useParams();
  const [animationsTriggered, setAnimationsTriggered] = useState(false);
  const [boxPosition, setBoxPosition] = useState<DOMRect | null>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const { playerA, playerB, amIPlayerA, someoneElseGame, bet, roomStatus } =
    useRoomStore();


  useEffect(() => {
    if (
      activeRoomId &&
      playerB.address !== NULL_ADDRESS &&
      roomStatus !== RoomStatusEnum.Closed
    ) {
      setAnimationsTriggered(true);
    }
  }, [activeRoomId, playerB]);

  const classNamePlayerACard = someoneElseGame
    ? 'order-1'
    : amIPlayerA
    ? 'order-1'
    : 'order-3';
  const classNamePlayerBCard = someoneElseGame
    ? 'order-3'
    : amIPlayerA
    ? 'order-3'
    : 'order-1';

  return (
    <div className="w-full h-full overflow-hidden relative">
      <div className="grid grid-cols-1 place-items-center h-screen overflow-hidden">
        <RoomMenu />
        <div
          ref={containerRef}
          className="flex flex-col sm:flex-row items-stretch justify-between max-w-[1840px] max-h-[1080px] min-h-[800px] w-full h-full px-[70px] py-[95px]"
        >
          <div
            className={classNames(
              classNamePlayerACard,
              'sm:max-w-[630px] sm:max-h-[890px] sm:w-[32%] w-full z-30',
              animationsTriggered && 'animate-appear-with-blur'
            )}
          >
            <PlayerCard
              position={classNamePlayerACard}
              playerAddress={playerA.address}
            />
          </div>
          <div
            className={classNames(
              `z-30 flex flex-col sm:w-[20%] w-full h-full sm:max-w-[350px] sm:min-w-[280px] order-2`,
              animationsTriggered && 'animate-appear'
            )}
          >
            <Score />
            <HistoryMoves boxPosition={boxPosition} />
          </div>
          <div
            className={classNames(
              classNamePlayerBCard,
              'sm:max-w-[630px] sm:w-[32%] sm:max-h-[890px] w-full z-30',
              animationsTriggered && 'animate-appear-with-blur'
            )}
          >
            <PlayerCard
              position={classNamePlayerBCard}
              playerAddress={playerB.address}
            />
          </div>
          <img
            src={floor}
            alt="floor"
            className="absolute bottom-0 left-0 right-0 z-0 h-[20%] w-full"
          />
          <img
            src={humanLeft}
            alt="guy"
            className={classNames(
              'absolute bottom-0 left-0 z-1 top-0 h-full max-w-[45%] opacity-60',
              animationsTriggered && 'animate-slide-in-from-left'
            )}
            style={{ filter: 'blur(6px)' }}
          />
          {playerB.address !== NULL_ADDRESS && activeRoomId && (
            <img
              src={humanRight}
              alt="guy"
              className={classNames(
                'absolute bottom-0 right-0 z-1 top-0 h-full max-w-[45%] opacity-60',
                animationsTriggered
                  ? 'animate-slide-in-from-right'
                  : 'animate-fast-appear-with-blur'
              )}
              style={{ filter: 'blur(6px)' }}
            />
          )}
          {activeRoomId && (
            <RoomBox
              setBoxPosition={setBoxPosition}
              containerRef={containerRef}
              animationsTriggered={animationsTriggered}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default DesktopRoom;
